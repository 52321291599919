.my-custom-table .amplify-table__td {
  background-color: #fff;
}

.my-custom-table .amplify-table__row {
  background-color: #fff;
}
.my-custom-table {
  border-radius: 20px;
}

.buttonCrud {
  border-radius: 0.5rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  justify-items: center;
  left: auto;
  right: auto;
}
