h2 {
  text-align: center;
  margin-bottom: 18px;
}

.container {
  display: flex;
  justify-content: center;
  margin: 40px 0px;
}

.container .forms {
  min-width: 70%;
  max-width: 80%;
  background-color: #fff;
  border-radius: 20px;
  padding: 36px 64px;
  margin-bottom: 20px;
}

.flex-item {
  justify-content: space-between;
  display: inline-flex;
  width: 100%;
}

#amplify-id- {
  background-color: gray;
}

.flex-item {
  display: flex;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (min-width: 1366px) {
  .flex-item {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
}

@media screen and (max-width: 1366px) {
  .flex-item {
    display: flex;
    flex-wrap: wrap;
  }

  .flex-item > * {
    width: 50%; /* show 2 items per line */
    box-sizing: border-box;
    padding: 0 10px; /* add padding in between items */
  }
}
