[data-amplify-authenticator] {
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: lightgray;
}

[data-amplify-authenticator] [data-amplify-router] {
  border-radius: 1rem;
}
/* 
.amplify-button--primary {
  background-color: #d7d7d7 !important;
} */

.boxSignIn {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1rem 0 0 0;
  align-items: center;
}

.logoSignIn {
  width: 8rem;
  height: 8rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

button.amplify-button.dashboardRedirect {
  width: 87%;
  margin-top: 1.5rem !important;
  font-weight: var(--amplify-font-weights-normal) !important;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

button.amplify-button.amplify-field-group__control.amplify-button--primary.amplify-button--fullwidth {
  background: #4a4a4a;
}

button.amplify-button.amplify-field-group__control.amplify-button--primary.amplify-button--fullwidth:focus {
  background-color: #222;
}
